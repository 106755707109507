import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Logo from '@/components/Logo'
import ItemProps from '@/components/Menu/ItemProps'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Menu.module.scss'
import { useMenu } from '@/bus/menu'
import { routes } from '@/config'

interface MobileProps extends DefaultComponentProps {
  items: ItemProps[]
}

const Mobile: FC<MobileProps> = ({ className = '', items = [] }) => {
  const { shown, toggleShown } = useMenu()
  const { t } = useTranslation('header')

  return (
    <nav
      className={clsx(styles['menu'], className, {
        [styles['menu_active']]: shown
      })}
    >
      <Button
        title={t('forms:buttons.toggleMenu') as string}
        size={'icon'}
        className={styles['menu__trigger']}
        onClick={() => toggleShown({ shown: !shown })}
      >
        <Icon name={shown ? 'cross/24' : 'menu/24'} />
      </Button>
      <div className={styles['menu__tooltip']}>
        <div className={styles['menu__tooltip-body']}>
          <Logo className={styles['menu__logo']} />
          <ul className={styles['menu__list']}>
            {items.map(({ id, label }) => (
              <li key={id} className={styles['menu__item']}>
                <Link
                  title={label}
                  className={clsx(styles['menu__link'])}
                  href={routes[id.toUpperCase()]}
                  onClick={() => toggleShown({ shown: false })}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Mobile
