import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'

import ItemProps from '@/components/Menu/ItemProps'

import useMediaQuery from '@/hooks/useMediaQuery'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import Desktop from './Desktop'
import Mobile from './Mobile'

const Menu: FC<DefaultComponentProps> = ({ className = '' }) => {
  const isDesktopScreen = useMediaQuery('(min-width: 1400px)')
  const { t } = useTranslation('menu')

  const ITEMS: ItemProps[] = t('list', { returnObjects: true })

  const desktopItems = useMemo(
    () => ITEMS.filter(({ desktop = false }) => desktop),
    [ITEMS]
  )
  return isDesktopScreen ? (
    <Desktop className={className} items={desktopItems} />
  ) : (
    <Mobile className={className} items={ITEMS} />
  )
}

export default Menu
