import { types } from './types'

export const getTextBySlugAction = (props: any) => {
  return {
    type: types['TEXTS/GET_DATA'],
    payload: props
  }
}

export const setTextsDataAction = (props: any) => {
  return {
    type: types['TEXTS/SET_DATA'],
    payload: props
  }
}
