//libraries
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use'

import FooterCopyright from '@/components/FooterCopyright'
import FooterLogo from '@/components/FooterLogo'
import FooterMenu from '@/components/FooterMenu/FooterMenu'
import { FooterMenuProps } from '@/components/FooterMenu/FooterMenuProps'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Footer.module.scss'

export const Footer: FC<DefaultComponentProps> = ({ className = '' }) => {
  const { t } = useTranslation('footer')

  const menu: FooterMenuProps[] = t('menu', { returnObjects: true })

  const intersectionRef = useRef(null)
  const [isAnimated, setAnimated] = useState(false)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4
  })

  useEffect(() => {
    if (intersection?.isIntersecting) {
      setAnimated(true)
    }
  }, [intersection?.isIntersecting])

  return (
    <footer
      ref={intersectionRef}
      className={clsx(styles['footer'], className, {
        [styles['footer_animation-start']]: isAnimated
      })}
    >
      <div className={styles['footer__content']}>
        <div className={styles['footer__col']}>
          <FooterLogo className={styles['footer__logo']} />
          <FooterCopyright />
        </div>
        {menu.map(({ heading, items, id }: FooterMenuProps) => (
          <div key={id} className={styles['footer__col']}>
            <FooterMenu
              heading={heading}
              items={items}
              className={styles['footer__menu']}
            />
          </div>
        ))}
      </div>
    </footer>
  )
}
