import { RestAxiosService } from '@/api/RestApiService'

export const fetch = async ({
  method = 'get',
  url,
  params = {},
  token = null
}) => {
  const headers = {}
  const isUpload = method === 'upload'
  const isFormData = params instanceof FormData

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (isUpload || isFormData) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  const data = method === 'get' ? { params } : { data: params }

  try {
    return await RestAxiosService({
      method: isUpload ? 'post' : method,
      url,
      ...data,
      headers
    })
  } catch (error) {
    return error
  }
}
