import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { FC, ReactNode, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Logo from '@/components/Logo'
import Menu from '@/components/Menu'

import useMediaQuery from '@/hooks/useMediaQuery'

import styles from './Main.module.scss'
import { useUi } from '@/bus/ui'
import { useUser } from '@/bus/user'
import { routes } from '@/config'
import Header from '@/layouts/Header'

const DefaultFooter = dynamic(
  () => import('@/layouts/Footer').then(({ Footer }) => Footer),
  {
    ssr: false
  }
)

interface LayoutProps {
  children: ReactNode
  footer?: ReactNode
  header?: ReactNode
}

export const Main: FC<LayoutProps> = ({
  children,
  footer = null,
  header = null
}) => {
  const [animationStart, setAnimationStart] = useState(false)

  const { settings } = useUi()

  const { isAuthorized } = useUser()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(min-width: 375px)')
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  useEffect(() => {
    let timeout: any

    timeout = setTimeout(() => {
      setAnimationStart(true)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <main
      className={clsx(styles['main'], {
        ['animation-start']: animationStart
      })}
    >
      {header ? (
        header
      ) : (
        <Header
          className={styles['main__header']}
          before={<Logo />}
          after={
            <>
              {isAuthorized ? (
                <Button
                  href={{ pathname: routes.SERVICES, spa: false }}
                  as={Link}
                  className={styles['section__button-link']}
                  before={<Icon name="arrows/right/28" />}
                >
                  {t('forms:buttons.services')}
                </Button>
              ) : (
                <>
                  {isMiddleScreen && (
                    <Button
                      as={Link}
                      href={routes.REGISTER}
                      variant="light"
                      className={styles['main__button']}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('forms:buttons.signup')
                        }}
                      />
                    </Button>
                  )}
                  {isSmallScreen && (
                    <Button
                      className={styles['main__button']}
                      as={Link}
                      href={routes.LOGIN}
                      before={<Icon name={'user/24'} />}
                    >
                      {t('forms:buttons.signin')}
                    </Button>
                  )}
                </>
              )}
            </>
          }
        >
          <Menu className={styles['header__menu']} />
        </Header>
      )}
      <section className={styles['main__content']}>{children}</section>

      <LazyLoad className={styles['main__lazy-footer']}>
        {footer ? footer : <DefaultFooter className={styles['main__footer']} />}
      </LazyLoad>
    </main>
  )
}
