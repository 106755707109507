import { fetch } from './fetch'
import { api } from '@/config'

const { API_APP_URL, TEXTS_URL } = api

const getTextsBySlug = ({ params: { slug, ...rest } }) => {
  return fetch({
    method: 'get',
    url: `${API_APP_URL}${TEXTS_URL}/${slug}`,
    params: { ...rest }
  })
}

export const textsAPI = {
  getTextsBySlug
}
