import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'

import rootReducer from './reducer'
import rootSaga from './saga'

const bindMiddleware = (middleware: SagaMiddleware<object>[]) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]))

  ;(store as any).sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export const wrapper = createWrapper(makeStore, { debug: false })
