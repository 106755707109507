import { fetch } from './fetch'
import { api } from '@/config'

const {
  API_URL,
  API_APP_URL,
  LOGIN_URL,
  LOGIN_2FA_URL,
  RECOVERY_URL,
  REGISTER_URL,
  USER_INFO_URL,
  SECURITY_SESSION_URL
} = api

const login = params =>
  fetch({
    method: 'post',
    url: `${API_APP_URL}${LOGIN_URL}`,
    ...params
  })

const login2FA = params =>
  fetch({ method: 'post', url: `${API_APP_URL}${LOGIN_2FA_URL}`, ...params })

const register = params =>
  fetch({ method: 'post', url: `${API_APP_URL}${REGISTER_URL}`, ...params })

const recovery = params =>
  fetch({ method: 'post', url: `${API_APP_URL}${RECOVERY_URL}`, ...params })

const getUserInfo = params =>
  fetch({ method: 'get', url: `${API_APP_URL}${USER_INFO_URL}`, ...params })

const updateUserInfo = params =>
  fetch({ method: 'patch', url: `${API_APP_URL}${USER_INFO_URL}`, ...params })

const startSecuritySession = params =>
  fetch({
    method: 'post',
    url: `${API_APP_URL}${SECURITY_SESSION_URL}`,
    ...params
  })

export const userAPI = {
  login,
  login2FA,
  recovery,
  register,
  getUserInfo,
  startSecuritySession,
  updateUserInfo
}
