const useSessionStorage = () => {
  const setData = (name: string, value: string) => {
    window.sessionStorage.setItem(name, value)
  }

  const getData = (name: string) => window.sessionStorage.getItem(name)

  const removeData = (name: string) => window.sessionStorage.removeItem(name)

  return { setData, getData, removeData }
}
export default useSessionStorage
