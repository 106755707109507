import { call, put } from 'redux-saga/effects'

import { fetchDataWorker } from '../../apiRequest/saga/worker'
import { types } from '../types'

import { textsAPI } from '@/api/textsAPI'
import { setTextsDataAction } from '@/bus/texts'
import { routes } from '@/config'

export function* getTextsWorker({ payload }: any) {
  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: textsAPI.getTextsBySlug,
    type: types['TEXTS'],
    params: { params: payload }
  })

  if (errors) {
    location.href = routes.HOME
  }

  if (response?.status === 'success') {
    yield put(setTextsDataAction({ data: response.data, loaded: true }))
  }
}
