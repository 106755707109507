import clsx from 'clsx'
import {
  FC,
  ReactFragment,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import { useIntersection } from 'react-use'

import useMediaQuery from '@/hooks/useMediaQuery'
import useSessionStorage from '@/hooks/useSessionStorage'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Header.module.scss'
import { useMenu } from '@/bus/menu'

interface HeaderProps extends DefaultComponentProps {
  type?: string
  before?: ReactNode
  after?: ReactNode | ReactFragment
  children?: ReactNode
}

const Header: FC<HeaderProps> = ({
  className = '',
  type = 'default',
  before = null,
  after = null,
  children = null
}) => {
  const { /*getData, setData,*/ removeData } = useSessionStorage()
  const { shown } = useMenu()
  const [isAnimated, setAnimated] = useState(false)
  const isMiddleScreen = useMediaQuery('(min-width: 768px)')

  const intersectionRef = useRef(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4
  })

  useEffect(() => {
    if (intersection?.isIntersecting) {
      setAnimated(true)
    }
  }, [intersection?.isIntersecting])

  return (
    <header
      ref={intersectionRef}
      id={'header'}
      {...{ ['data-type']: type }}
      {...{ ['data-mobile-type']: shown ? 'light' : 'default' }}
      className={clsx(
        styles['header'],
        className,
        {
          [styles['header_animation-start']]: isAnimated
        },
        { [styles['header_with-shown-menu']]: shown }
      )}
    >
      {before && <div className={styles['header__side']}>{before}</div>}
      {children && <div className={styles['header__content']}>{children}</div>}
      {isMiddleScreen && after && (
        <div className={styles['header__side']}>{after}</div>
      )}
    </header>
  )
}
export default Header
