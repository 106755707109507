export const types = {
  'USER/LOGIN': 'USER/LOGIN',
  'USER/REGISTER': 'USER/REGISTER',
  'USER/RECOVERY': 'USER/RECOVERY',
  USER: 'USER',
  'USER/LOADING': 'USER/LOADING',
  'USER/ERRORS': 'USER/ERRORS',
  'USER/SET_DATA': 'USER/SET_DATA',
  'USER/INFO': 'USER/INFO',
  'USER/LOGIN_2FA': 'USER/LOGIN_2FA',
  'USER/CANCEL_2FA': 'USER/CANCEL_2FA',
  'USER/START_SECURITY_SESSION': 'USER/START_SECURITY_SESSION',
  'USER/UPDATE_USER_INFO': 'USER/UPDATE_USER_INFO'
}
