import { takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import {
  login2FAWorker,
  loginWorker,
  recoveryWorker,
  registerWorker,
  startSecuritySessionWorker,
  updateUserInfoWorker,
  userInfoWorker
} from './worker'

export function* watchUser() {
  // @ts-ignore
  yield takeLatest(types['USER/LOGIN'], loginWorker)
  yield takeLatest(types['USER/RECOVERY'], recoveryWorker)
  yield takeLatest(types['USER/REGISTER'], registerWorker)
  yield takeLatest(types['USER/INFO'], userInfoWorker)
  yield takeLatest(types['USER/LOGIN_2FA'], login2FAWorker)
  yield takeLatest(
    types['USER/START_SECURITY_SESSION'],
    startSecuritySessionWorker
  )
  yield takeLatest(types['USER/UPDATE_USER_INFO'], updateUserInfoWorker)
}
