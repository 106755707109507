import { types } from './types'

export const toggleShown = props => ({
  type: types['MENU/TOGGLE'],
  payload: props
})

export const setActiveItem = props => ({
  type: types['MENU/SET_ACTIVE_ITEM'],
  payload: props
})
