import '@splidejs/react-splide/css/core'
import { NextPage } from 'next'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'

import GoogleTag from '@/components/GoogleTag'
import Notifications from '@/components/Notifications'
import ReferralProgram from '@/components/ReferralProgram'
import UseDesk from '@/components/UseDesk'

import SEO from '../../next-seo.config'

import { Root } from '@/layouts'
import { wrapper } from '@/store'
import '@/styles/globals.scss'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, ...rest }: AppPropsWithLayout) {
  const { t } = useTranslation()

  const { store, props } = wrapper.useWrappedStore(rest)
  const { pageProps } = props

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <Provider store={store}>
        <>
          <DefaultSeo
            title={t('head:title') as string}
            description={t('head:description') as string}
            {...SEO}
          />
          <GoogleTag />

          <ParallaxProvider>
            <Root>
              {getLayout(<Component {...pageProps} />)}
              <ReferralProgram />
              <Notifications />
              <UseDesk />
            </Root>
          </ParallaxProvider>
        </>
      </Provider>
    </>
  )
}

export default appWithTranslation(App)
