import { types } from './types'

const initialState = {
  shown: false,
  activeItem: null
}

export function menuReducer(state = initialState, action) {
  switch (action.type) {
    case types['MENU/SET_ACTIVE_ITEM']:
    case types['MENU/TOGGLE']:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
