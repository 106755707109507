import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './BackButton.module.scss'
import { routes } from '@/config'

interface BackButtonProps extends DefaultComponentProps {
  url?: string
}
const BackButton: FC<BackButtonProps> = ({
  className = '',
  url = routes.HOME
}) => {
  const { t } = useTranslation()
  return (
    <Button
      title={t('forms:buttons.goBack') as string}
      as={Link}
      size={'l'}
      className={clsx(className, styles['back-button'])}
      href={url}
    >
      <Icon
        className={styles['back-button__icon']}
        name={'arrows/left/32'}
        color={'#fff'}
      />
    </Button>
  )
}
export default BackButton
