import { useDispatch, useSelector } from 'react-redux'

import { getTextBySlugAction } from '@/bus/texts/actions'
import { IRootState } from '@/store/reducer'

export const useTexts = () => {
  const dispatch = useDispatch()
  const {
    loading,
    loaded,
    errors,
    failed,
    data = {}
  } = useSelector(({ texts }: IRootState) => texts)

  const getTextsBySlug = (props: object) => {
    const action = getTextBySlugAction(props)
    dispatch(action)
  }

  return {
    data,
    getTextsBySlug,
    loading,
    loaded,
    errors,
    failed
  }
}
