import { useCallback, useEffect } from 'react'

import { useUser } from '@/bus/user'

type UseDeskProp = {
  email?: string | null
  id?: number | null
  usedesk_token?: string | null
}
const UseDesk = () => {
  const { data = {}, updateUserInfo, isAuthorized } = useUser()

  const initUseDesk = useCallback(
    ({ email = null, id = null, usedesk_token = null }: UseDeskProp) => {
      if (isAuthorized === null) {
        return
      }
      const oldScriptElement = document.getElementById('usedesk-script')
      const oldMessengerElement = document.getElementById('usedesk-messenger')

      if (oldScriptElement) {
        oldScriptElement.remove()
      }

      if (oldMessengerElement) {
        oldMessengerElement.remove()
      }

      const props: {
        email: string
        phone: string
        name: string
        token?: string
      } = {
        email: email ?? '',
        phone: '-',
        name: email ?? ''
      }

      if (usedesk_token) {
        props.token = usedesk_token
      }

      // @ts-ignore
      window.__widgetInitCallback = function () {
        // @ts-ignore
        let expectedWidget = window.usedeskMessenger

        expectedWidget.identify({
          ...props,
          additional_fields: [
            {
              id: 24625,
              value: id ?? ''
            }
          ]
        })
      }

      // @ts-ignore
      window.__usedeskWidgetInitedCallback = function () {
        // @ts-ignore
        const token = window.usedeskMessenger.getChatToken()

        if (usedesk_token !== token) {
          updateUserInfo({ usedesk_token: token })
        }

        /*if (usedesk_token !== token) {
              dispatch({
                  type: 'USER/UPDATE_USER',
                  payload: { usedesk_token: token },
              })
          }*/
      }

      let usedeskScriptElement = document.createElement('script')

      usedeskScriptElement.setAttribute('id', `usedesk-script`)

      usedeskScriptElement.setAttribute(
        'src',
        // @ts-ignore
        `https://lib.usedesk.ru/secure.usedesk.ru/widget_${USEDESK_ID}.js`
      )
      document.body.appendChild(usedeskScriptElement)
    },
    [isAuthorized, updateUserInfo]
  )

  useEffect(() => {
    if (isAuthorized === null) {
      return
    }
    initUseDesk(data)
  }, [data, initUseDesk, isAuthorized])

  return null
}

export default UseDesk
