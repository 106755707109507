import clsx from 'clsx'
import { FC } from 'react'

import Icon from '@/components/Icon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './FooterLogo.module.scss'

const FooterLogo: FC<DefaultComponentProps> = ({ className = '' }) => {
  return (
    <div className={clsx(styles['footer-logo'], className)}>
      <Icon name={`adsimple/140`} />
    </div>
  )
}
export default FooterLogo
