interface RoutesProps {
  [key: string]: string
}
export const routes: RoutesProps = {
  HOME: '/',
  NETWORKS: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  RECOVERY: '/recovery',
  CHECK_EMAIL: '/check-email',
  USER_AGREEMENTS: '/',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  HELP: '/faq',
  CONTACTS: '/contacts',
  SUPPORT: '/tickets/new',
  TELEGRAM: 'https://t.me/AdSimle_me',
  FACEBOOK: 'https://www.facebook.com/share/fVbnYg6FbBk78tHm/?mibextid=LQQJ4d',
  YOUTUBE: 'https://www.youtube.com/channel/UCkp8PHO7LYgMn5Ddth2f-DQ',
  AUTH_LOGIN: '/auth/login',
  SERVICES: '/services/',
  ADD_NETWORKS: '/#add_networks',
  RENT_ADS_ACCOUNT: '/#rent_ads_account',
  DEPOSIT_METHODS: '/#deposit_methods',
  AGENCY: '/#agency',
  CPA_NETWORK: '/#cpa_network'
} as const
