import Script from 'next/script'
import { FC } from 'react'

import { GA_TRACKING_ID } from '@/utils/gtag'

const GoogleTag: FC = () => {
  const isAnalytics = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true'
  if (!isAnalytics) {
    return null
  }

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
            'gtm.start':
            new Date().getTime(), event: 'gtm.js'
          });
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true
            j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${GA_TRACKING_ID}');
          window.dataLayer = window.dataLayer || [];
            function gtag () {dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'AW-827264926');
            `}
    </Script>
  )
}
export default GoogleTag
