import { FC, ReactNode } from 'react'

import styles from './DoublePanel.module.scss'

interface DoublePanelProps {
  children: ReactNode
  side?: ReactNode
}
export const DoublePanel: FC<DoublePanelProps> = ({
  children = null,
  side = null
}) => {
  return (
    <main className={styles['double-panel']}>
      <section className={styles['double-panel__content']}>
        <div className={styles['double-panel__content-inner']}>{children}</div>
      </section>
      <aside className={styles['double-panel__side']}>{side}</aside>
    </main>
  )
}
