import { types } from './types'

const initialState = {
  settings: {
    menu: true
  }
}

export function uiReducer(state = initialState, action: any) {
  switch (action.type) {
    case types['UI/SET_SETTINGS']: {
      return {
        ...state,
        settings: { ...state.settings, ...action.payload }
      }
    }

    default:
      return state
  }
}
