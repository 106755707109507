import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'

import { LinkProp } from '@/components/Link/LinkProp'

const PROTOCOL = new RegExp('^(http|https)://', 'i')

const Link: FC<LinkProp> = ({
  children = null,
  href = '/',
  locale = null,
  ...rest
}) => {
  // все эти манипуляции нужны для того, чтобы можно было передавать разные виды ссылок
  // от самого простого string с или без указания домена
  // до сложного объекта
  // {
  //   pathname: string
  //   query?: { [key: string]: string }
  // }
  // Стандартный компонент Link от Next не подошёл из-за того, что он делает лишние запросы в сбилденных файлах при клике на ссылку, которые порождают на проде ошибки

  const {
    i18n: { language }
  } = useTranslation()

  // текущая языковая версия
  /*
  const currentLocale = useMemo(() => locale || language, [locale, language])
*/

  const { pathname = '', query = {} } =
    typeof href === 'object' && href !== null ? href : { pathname: href }

  // ссылка и хжш тэг
  const [path = '', hash = ''] = useMemo(() => pathname.split('#'), [pathname])

  // если передана ссылка с доменом
  const isHrefAbs = useMemo(() => PROTOCOL.test(path), [path])

  // формирование гет параметров для ссылки
  const queryString = useMemo(
    () =>
      Object.keys(query).length
        ? '?' + new URLSearchParams(query).toString()
        : '',
    [query]
  )

  // формирование хэш тега для ссылки
  const hashString = useMemo(() => (hash.length ? `#${hash}` : ''), [hash])

  const url = useMemo(() => {
    // если переданная ссылка с доменом, то отдаём её без добавления языковой версии
    if (isHrefAbs) {
      return path + queryString + hashString
    }

    const url = []

    /*// если русская языковая версии или ссылка на кабинет, то добавляем языковую версию
    if (currentLocale !== 'ru' /!* || path === routes.SERVICES*!/) {
      url.push('/', currentLocale)
    }*/

    /*// если ссылка не на главную или на главную, но русскую версию добавляем ссылку
    if (path !== '/' || (path === '/' && currentLocale === 'ru')) {

    }*/

    url.push(path)

    // добавляем хэш
    url.push(hashString)

    // добавляем гет парметры
    url.push(queryString)

    // возвращаем сгенерированную ссылку
    return url.join('')
  }, [isHrefAbs, /* currentLocale,*/ path, hashString, queryString])

  return (
    // @ts-ignore
    <a {...rest} href={url}>
      {children}
    </a>
  )
}
export default Link
