export const api = {
  API_URL: '/api/v1',
  API_APP_URL: '/api/app/v1',
  LOGIN_URL: '/security/auth',
  LOGIN_2FA_URL: '/security/auth/two_factor',
  RECOVERY_URL: '/security/password/recover',
  REGISTER_URL: '/security/register',
  USER_INFO_URL: '/user',
  TEXTS_URL: '/texts',
  SECURITY_SESSION_URL: '/security/auth/start',
  ACCESS_TOKEN_URL: '/security/refresh'
}
